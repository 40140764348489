<template>
  <Layout>
    <div
      class="page-title-box d-flex align-items-center justify-content-between"
    >
      <h4 class="mb-0">Edit Universal Member</h4>
      <div class="page-title-right">
        <!-- <router-link
          :to="{
            name: 'universal',
            query: { isUniversal: true },
          }"
        > -->
        <span @click="$router.go(-1)" style="cursor: pointer; color: grey"
          >Back</span
        >
        <!-- </router-link> -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form
              enctype="multipart/form-data"
              @submit.prevent="
                this.$route.name == 'edit-universal'
                  ? 'Save Data'
                  : 'Update Data'
              "
            >
              <div class="row">
                <b-form-group id="input-group-2" class="col-6">
                  <label for="input-1"
                    >First Name <span style="color: red">*</span></label
                  >
                  <b-form-input
                    id="input-2"
                    v-model="form.first_name"
                    placeholder="Enter First Name"
                    :class="{
                      'is-invalid': submitted && $v.form.first_name.$invalid,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.form.first_name.required"
                    class="invalid-feedback"
                  >
                    First name is required.
                  </div>
                </b-form-group>
                <b-form-group id="input-group-2" class="col-6">
                  <label for="input-1">Last Name</label>
                  <b-form-input
                    id="input-2"
                    v-model="form.last_name"
                    placeholder="Enter Last Name"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-4">
                  <label for="input-multi">Speciality</label>
                  <b-form-select
                    v-model="form.speciality"
                    :options="getSpeciality"
                    value-field="title"
                    text-field="title"
                  >
                  </b-form-select>
                </b-form-group>
                <b-form-group id="input-group-2" class="col-4">
                  <label for="input-1">Email</label>
                  <b-form-input
                    id="input-2"
                    v-model="form.email"
                    placeholder="Enter Email"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-4">
                  <label for="input-multi">Mobile Number</label>
                  <b-form-input
                    v-model="form.mobile_number"
                    id="i12"
                    placeholder="Enter Mobile Number"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group id="input-group-2" class="col-3">
                  <label for="input-1">Country Code</label>
                  <b-form-input
                    id="input-2"
                    v-model="form.country_code"
                    placeholder="Enter Country Code"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-3">
                  <label for="input-multi">Country</label>
                  <b-form-select
                    v-model="form.country"
                    :options="getCountry"
                    value-field="name"
                    text-field="name"
                    @change="changeCountry(form.country ?? '')"
                  >
                  </b-form-select>
                </b-form-group>
                <b-form-group class="col-3">
                  <label for="input-multi">State</label>
                  <b-form-select
                    id="input-multi"
                    v-model="form.state"
                    :options="getState"
                    value-field="state"
                    text-field="state"
                    @change="changeState(form.country ?? '', form.state ?? '')"
                  >
                  </b-form-select>
                </b-form-group>
                <b-form-group class="col-3">
                  <label for="input-multi">City</label>
                  <b-form-select
                    id="verified"
                    v-model="form.city"
                    value-field="city"
                    text-field="city"
                    :options="getCity"
                  >
                  </b-form-select>
                </b-form-group>
                <b-form-group class="col-3" v-if="getMemberType">
                  <label for="input-multi">Member Type</label>
                  <b-form-select
                    v-model="form.member_type"
                    :options="getMemberType"
                    value-field="value"
                    text-field="label"
                  >
                  </b-form-select>
                </b-form-group>
                <b-form-group id="input-group-2" class="col-3">
                  <label for="input-1">Licence Number</label>
                  <b-form-input
                    id="input-2"
                    v-model="form.reg_no"
                    placeholder="Enter Licence Number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-3">
                  <label for="date-of-birth">Date of birth </label>
                  <b-form-input
                    id="date-of-birth"
                    placeholder="Enter Date of birth"
                    v-model="form.dob"
                    type="date"
                    :max="disabledDates()"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group class="col-3">
                  <label for="date-of-birth">Registration Year </label>
                  <b-form-input
                    id="date-of-birth"
                    placeholder="Enter Registration Year"
                    v-model="form.reg_date"
                    type="date"
                    :max="disabledDates()"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-5"
                  label="Profile Image"
                  label-for="profile-img"
                >
                  <b-form-file
                    id="profile-img"
                    accept="image/*"
                    ref="profile_image"
                    @change="readFile($event, 'profile_image')"
                    placeholder="Choose a file [Upload Max File Size : 2MB]"
                  ></b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-universal' && edit.profile_image_url
                    "
                  >
                    <img
                      :src="edit.profile_image_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="profile_image_url">
                    <img
                      :src="profile_image_url"
                      width="128px"
                      height="128px"
                      ref="profile_image"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <b-form-group class="col-5" v-if="getMemberType">
                  <label for="input-multi">Is Verified</label>
                  <b-form-select
                    v-model="form.is_verified"
                    :options="verified_options"
                    value-field="value"
                    text-field="text"
                  >
                  </b-form-select>
                </b-form-group>
                <div
                  class="col-2"
                  style="display: flex; align-items: start; padding-top: 38px"
                >
                  <div style="display: flex; gap: 10px">
                    <span>Internal User Status</span>
                    <img
                      src="/tick.svg"
                      height="20px"
                      style="color: red"
                      v-if="form.internal_user"
                    />
                    <img
                      src="/close.svg"
                      height="20px"
                      style="color: red"
                      v-else
                    />
                  </div>
                </div>
              </div>
              <div
                v-b-modal="'noteModal'"
                class="ml-2"
                @click="sendInfoNote(mainid)"
              >
                <label class="mr-2">For adding Notes</label>
                <i
                  class="mdi mdi-note-multiple"
                  style="color: #1cbb8c; font-size: 20px"
                ></i>
              </div>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2 mt-2"
                @click.prevent="updateData"
              >
                Update Data
              </b-button>
            </b-form>
            <b-modal id="noteModal" size="lg">
              <h2 class="text-center py-3">Notes</h2>
              <div class="container py-2">
                <div class="row" v-for="message in messages" :key="message.id">
                  <div
                    class="col-auto text-center flex-column d-none d-sm-flex"
                  >
                    <div class="row h-50">
                      <div class="col">&nbsp;</div>
                      <div class="col">&nbsp;</div>
                    </div>
                    <h5 class="m-2">
                      <span class="badge1 badge-pill bg-light border"
                        >&nbsp;</span
                      >
                    </h5>
                    <div class="row h-50">
                      <div class="col border-right">&nbsp;</div>
                      <div class="col">&nbsp;</div>
                    </div>
                  </div>
                  <div class="col py-2">
                    <div class="card">
                      <div
                        class="card-body"
                        style="
                          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
                        "
                      >
                        <div class="float-right text-muted">
                          {{ getSessionStart(message.created_at) }}
                        </div>
                        <h4
                          class="card-title mb-0"
                          v-if="message.notes"
                          v-html="renderClickableLink(message.notes)"
                        ></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <template v-slot:modal-footer class="p-0">
                <b-input-group
                  @submit.prevent="sendMessage('out')"
                  id="person2-form"
                >
                  <label
                    for="person2-input"
                    class="mb-0 mr-2 d-flex align-items-center"
                    >You :
                  </label>
                  <b-input
                    v-model="message"
                    id="person2-input"
                    type="text"
                    placeholder="Type your message"
                  ></b-input>
                  <b-button
                    :disabled="message == ''"
                    @click="storeNotes(id, message)"
                    type="submit"
                    class="ml-2"
                    >Send</b-button
                  >
                </b-input-group>
              </template>
            </b-modal>
            <div class="row mt-4">
              <div class="col-12">
                <div
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <h5 class="mb-0">Workplace Details</h5>
                  <div class="d-flex align-items-center">
                    <i class="fas fa-pen" />
                    <span
                      class="opt ml-2 mb-0"
                      style="color: #5664d2; cursor: pointer; font-weight: 600"
                      @click="addWorkPlace"
                    >
                      Add Workplace Details
                    </span>
                  </div>
                </div>
                <div
                  id="post"
                  class="table-responsive mb-0"
                  v-if="workplace_details.length > 0"
                >
                  <b-table
                    striped
                    bordered
                    :items="workplace_details"
                    :fields="workplace_details_fields"
                    :sort-by.sync="sortBy"
                    :per-page="0"
                    :sort-desc.sync="sortDesc"
                    :filter-included-fields="filterOn"
                    responsive="sm"
                    class="table-bordered table-hover"
                    :key="key"
                  >
                    <template v-slot:cell(currently_working)="row">
                      <img
                        src="/tick.svg"
                        height="16px"
                        style="color: red"
                        v-if="
                          row.item.currently_working != null &&
                          row.item.currently_working != ''
                        "
                      />
                      <img
                        src="/close.svg"
                        height="16px"
                        style="color: red"
                        v-else
                      />
                    </template>
                    <template v-slot:cell(formate_end_date)="row">
                      <span
                        v-if="row.item.formate_end_date && row.item.end_date"
                        >{{ row.item.formate_end_date }}</span
                      >
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(formate_start_date)="row">
                      <span
                        v-if="
                          row.item.formate_start_date != null &&
                          row.item.start_date != null
                        "
                        >{{ row.item.formate_start_date }}</span
                      >
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(designation)="row">
                      <span v-if="row.item.designation">{{
                        row.item.designation
                      }}</span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(place_of_work)="row">
                      <span v-if="row.item.place_of_work">{{
                        row.item.place_of_work
                      }}</span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(country)="row">
                      <span v-if="row.item.country">{{
                        row.item.country
                      }}</span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(city)="row">
                      <span v-if="row.item.city">{{ row.item.city }}</span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(state)="row">
                      <span v-if="row.item.state">{{ row.item.state }}</span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(work_place_type)="row">
                      <span v-if="row.item.work_place_type">{{
                        row.item.work_place_type
                      }}</span>
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(edit)="row">
                      <i
                        class="fa fa-edit edit-i"
                        style="color: #5664d2; cursor: pointer"
                        @click="getWorkPlace(row.item.id)"
                      ></i>
                    </template>
                  </b-table>
                </div>
                <template v-if="workplace_details.length == 0">
                  <div class="no_content my-4">
                    <div
                      style="
                        display: grid;
                        justify-content: center;
                        background: aliceblue;
                      "
                      class="py-3"
                    >
                      <img src="../../../../public/noData.svg" alt="" />
                      <span class="no_content_text text-center"
                        >No Workplaces Available</span
                      >
                    </div>
                  </div>
                </template>
              </div>
              <div class="col-12 my-4">
                <div
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <h5 class="mb-0">Academics Details</h5>
                  <div class="d-flex align-items-center">
                    <i class="fas fa-pen" />
                    <span
                      class="opt ml-2 mb-0"
                      style="color: #5664d2; cursor: pointer; font-weight: 600"
                      @click="addAcademics"
                    >
                      Add Academic Details
                    </span>
                  </div>
                </div>
                <div
                  id="post"
                  class="table-responsive mb-0"
                  v-if="academic_details.length > 0"
                >
                  <b-table
                    striped
                    bordered
                    :items="academic_details"
                    :fields="academic_details_fields"
                    :filter-included-fields="filterOn"
                    responsive="sm"
                    class="table-bordered table-hover"
                    :key="key"
                  >
                    <template v-slot:cell(course_name)="row">
                      <span
                        v-if="row.item.course_name"
                        class="c_touchpt_name"
                        >{{ row.item.course_name }}</span
                      >
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(other_course_name)="row">
                      <span
                        v-if="row.item.other_course_name"
                        class="c_touchpt_name"
                        >{{ row.item.other_course_name }}</span
                      >
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(institute_name)="row">
                      <span
                        v-if="row.item.institute_name"
                        class="c_touchpt_name"
                        >{{ row.item.institute_name }}</span
                      >
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(other_institute_name)="row">
                      <span
                        v-if="row.item.other_institute_name"
                        class="c_touchpt_name"
                        >{{ row.item.other_institute_name }}</span
                      >
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(formate_end_date)="row">
                      <span
                        v-if="
                          row.item.formate_end_date != null &&
                          row.item.end_date != null
                        "
                        >{{ row.item.formate_end_date }}</span
                      >
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(formate_start_date)="row">
                      <span
                        v-if="
                          row.item.formate_start_date != null &&
                          row.item.start_date != null
                        "
                        >{{ row.item.formate_start_date }}</span
                      >
                      <span v-else>---</span>
                    </template>
                    <template v-slot:cell(currently_pursuing)="row">
                      <img
                        src="/tick.svg"
                        height="16px"
                        style="color: red"
                        v-if="
                          row.item.currently_pursuing != null &&
                          row.item.currently_pursuing != ''
                        "
                      />
                      <img
                        src="/close.svg"
                        height="16px"
                        style="color: red"
                        v-else
                      />
                    </template>
                    <template v-slot:cell(edit)="row">
                      <i
                        class="fa fa-edit edit-i"
                        style="color: #5664d2; cursor: pointer"
                        @click="getAcademics(row.item.id)"
                      ></i>
                    </template>
                  </b-table>
                </div>
                <template v-if="academic_details.length == 0">
                  <div class="no_content my-4">
                    <div
                      style="
                        display: grid;
                        justify-content: center;
                        background: aliceblue;
                      "
                      class="py-3"
                    >
                      <img src="../../../../public/noData.svg" alt="" />
                      <span class="no_content_text text-center"
                        >No Academics Available</span
                      >
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <b-modal
              id="bvModal1"
              ref="modal"
              title="Update Workplace Details"
              @ok="handleWorkplaces"
              @cancel="closeModal"
              v-model="is_work_place_modal"
              no-close-on-backdrop
              centered
              hide-header-close
              ok-title="Update"
              cancel-title="Close"
              size="lg"
            >
              <form ref="form" @submit.stop.prevent="handleSubmitWorkplaces">
                <div class="row">
                  <b-form-group class="col-6">
                    <label for="input-2">Designation </label>
                    <b-form-input
                      id="input-2"
                      v-model="form2.designation"
                      placeholder="Enter Designation"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label for="input-2">Place of work </label>
                    <b-form-input
                      id="input-2"
                      v-model="form2.place_of_work"
                      placeholder="Enter Place of work"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label-for="work_place_type" class="col-6">
                    <label for="input-2">Type of Workplace </label>
                    <b-form-select
                      id="work_place_type"
                      v-model="form2.work_place_type"
                      :options="workplacelist"
                      value-field="name"
                      text-field="name"
                    >
                    </b-form-select>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label for="input-2">Address </label>
                    <b-form-input
                      id="input-2"
                      v-model="form2.address"
                      placeholder="Enter Address"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label-for="country" class="col-4">
                    <label for="input-2">Country </label>
                    <b-form-select
                      id="country"
                      v-model="form2.country"
                      :options="getCountry"
                      value-field="name"
                      text-field="name"
                      @change="resetWorkPlaceStateAndCity"
                    >
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label-for="state2" class="col-4">
                    <label for="input-2">State </label>
                    <b-form-select
                      id="state2"
                      v-model="form2.state"
                      :options="allWorkPlaceState"
                      value-field="state"
                      text-field="state"
                      @change="resetWorkPlaceCity"
                    >
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label-for="city2" class="col-4">
                    <label for="input-2">City </label>
                    <div v-if="form2.city">
                      <b-form-input
                        id="city2"
                        v-model="form2.city"
                        :value-field="city"
                        text-field="city"
                      ></b-form-input>
                    </div>
                    <div v-else>
                      <b-form-select
                        id="city2"
                        v-model="form2.city"
                        :options="allWorkPlaceCity"
                        value-field="city"
                        text-field="city"
                      >
                      </b-form-select>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label for="start-date">Start Date </label>
                    <b-form-input
                      id="start-date"
                      placeholder="Enter Start Date"
                      v-model="form2.start_date"
                      type="date"
                      :min="disabledDates()"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="form2.currently_working == 0"
                    class="col-6"
                  >
                    <label for="end-date">End Date </label>
                    <b-form-input
                      id="end-date"
                      placeholder="Enter End Date"
                      v-model="form2.end_date"
                      type="date"
                      :min="disabledDates()"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group class="col-12">
                    <div style="display: flex; gap: 10px">
                      <b-form-checkbox
                        v-model="form2.currently_working"
                        class="pl-4 py-2"
                        >Currently Working</b-form-checkbox
                      >
                    </div>
                  </b-form-group>
                </div>
              </form>
            </b-modal>
            <b-modal
              id="bvModal2"
              ref="modal"
              title="Update Academics Details"
              @ok="handleAcademics"
              @cancel="closeModal"
              v-model="is_academic_modal"
              no-close-on-backdrop
              centered
              hide-header-close
              ok-title="Update"
              cancel-title="Close"
              size="lg"
            >
              <form ref="form" @submit.stop.prevent="handleSubmitAcademics">
                <div class="row">
                  <b-form-group label-for="qualification" class="col-6">
                    <label>Qualification </label>
                    <b-form-select
                      id="qualification"
                      v-model="form4.course_name"
                      :options="qualification"
                      value-field="course_name"
                      text-field="course_name"
                      @change="updateQulificationRefNo"
                    >
                    </b-form-select>
                  </b-form-group>
                  <div v-if="form4.course_name == 'Others'" class="col-6">
                    <b-form-group>
                      <label for="input-2">Other Course Name </label>
                      <b-form-input
                        id="input-2"
                        v-model="form4.other_course_name"
                        placeholder="Enter Course Name"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <b-form-group label-for="institute" class="col-6">
                    <label>College of graduation </label>
                    <b-form-select
                      id="institute"
                      v-model="form4.institute_name"
                      :options="institute"
                      value-field="institute_name"
                      text-field="institute_name"
                      @change="updateInstituteRefNo"
                    >
                    </b-form-select>
                  </b-form-group>
                  <div v-if="form4.institute_name == 'Other'" class="col-6">
                    <b-form-group>
                      <label for="input-2">Other Institute Name </label>
                      <b-form-input
                        id="input-2"
                        v-model="form4.other_institute_name"
                        placeholder="Enter Institute Name"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <b-form-group class="col-6">
                    <label for="start-date">Start Date </label>
                    <b-form-input
                      id="start-date"
                      placeholder="Enter Start Date"
                      v-model="form4.start_date"
                      type="month"
                      :min="disabledDates()"
                    >
                    </b-form-input>
                  </b-form-group>
                  <div v-if="form4.currently_pursuing == 0" class="col-6">
                    <label for="ac-end-date1">End Date </label>
                    <b-form-input
                      id="ac-end-date1"
                      placeholder="Enter End Date"
                      v-model="form4.end_date"
                      type="month"
                      :min="disabledDates()"
                    >
                    </b-form-input>
                  </div>
                  <div class="col-12">
                    <div style="display: flex; gap: 10px">
                      <b-form-checkbox
                        v-model="form4.currently_pursuing"
                        value="1"
                        unchecked-value="0"
                        class="pl-3.5 py-2"
                        >Currently Pursuing</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import MixinRequest from "../../../mixins/request";
import universal_mixin from "../../../mixins/ModuleJs/universal";
import { required } from "vuelidate/lib/validators";
import { universal } from "../../../../src/js/path";
import moment from "moment";

export default {
  data() {
    return {
      submitted: false,
      title: "Edit Universal Member",
      items: [
        {
          text: "Back",
          href: "/universal-member",
        },
        {
          text: "Data",
        },
      ],
      messages: [],
    };
  },
  methods: {
    disabledDates() {
      return new Date().toISOString().slice(0, 16);
    },
    sendInfoNote(val) {
      this.id = val;
      this.fetchNotes(this.id);
    },
    getSessionStart: function (session_time) {
      let dateTime1 = moment(session_time).format("LLLL");
      return dateTime1;
    },
    async fetchNotes(val) {
      const url = universal.fetchNotes + val;
      const data = await this.getRequest(url);
      this.messages = data.response;
    },

    async storeNotes(val, message) {
      this.message = "";
      try {
        const url = universal.storeNotes;
        let dataAppend = new FormData();
        dataAppend.append("universal_id", val);
        dataAppend.append("notes", message);
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.isClickedMciReferrel = false;
        this.fetchNotes(this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
  mixins: [MixinRequest, universal_mixin],
  validations: {
    form: {
      first_name: { required },
    },
  },
  components: {
    Layout,
  },
};
</script>
